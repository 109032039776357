body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9fafb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* landing page styling */
.hero_section_vector {
  width: 80rem;
  height: 37rem;
  margin-left: 20px;
}

/* Background image */
.hero_bg {
  /* background: url('../public/bg.svg') no-repeat center center fixed;
  background-size: cover; */
  /* background-color: aqua; */
  background: #1d4ed8;
  /* background: #dcecf9; */
}

.faqcard {
  box-shadow: none !important;
}

.opacity-5 {
  opacity: 0.09 !important;
}
